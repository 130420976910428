


















































import { defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    ORegistrationForm: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-form.vue"
      ),
    ORegistrationMessage: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-registration-message.vue"
      ),
  },

  setup(_, { root }) {
    const model = reactive({
      name: "",
      numberOfPartnersInRow: 1,
      state: true,
      priority: 1,
    });

    const state = reactive({
      empty: false,
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      dialog: false,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Grupa partnerów już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = (action?: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name,
        numberOfPartnersInRow: model.numberOfPartnersInRow,
        state: model.state,
        priority: model.priority,
      };

      state.loading = true;

      axiosInstance
        .post(`/event/${root.$route.params.id}/partner/group`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;
          model.name = "";
          model.numberOfPartnersInRow = 1;
          model.state = true;
          model.priority = 1;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc(
              "event.panel.website.config.partners.single.add.add.success"
            ),
          });
          if (action === "close") {
            root.$router.push(
              (root.$route.query as { next: string }).next || {
                name: "panel.event.view.website.partner.view",
              }
            );
          }
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const numberOfPartnersInRow = [1, 2, 3, 4];

    return { model, state, onSubmit, getErrorMessage, numberOfPartnersInRow };
  },
});
